import './index.less';

import Vue from "vue";
import Component from "vue-class-component";

import {
  AttachmentPage,
  ChildAttachmentGroupDtos,
  childAttachmentService,
  getAttachmentPageInput,
  IChildAttachment,
  SaveAttachmentPageDto,
} from "@/app/apiServices/childAttachment/public-api";
import helperService from "@/app/apiServices/helper/helperService";
import { Upload } from "@/app/components/index"
import { thisCity_stepsList } from '@/core/utils/enum/thisCity';
import { getStepsIcon } from '@/core/utils';
import { hasRegister, queryZZKMessage } from '@/config/message';
import { BaseResultDto } from '@/app/apiServices/baseDto/resultDto';
import storage from '@/core/utils/storage';

@Component({
  components: {
    Upload,
  },
})
export default class MaterialInformationTs extends Vue {
  attachmentPage: AttachmentPage[] = [];
  formModel = new SaveAttachmentPageDto();
  loading = false; //是否加载电子证照
  stepsList = thisCity_stepsList;
  activeSteps = 2;
  childId = 0;

  getStepsIcon(index: number) {
    return getStepsIcon(index, this.activeSteps);
  }

  getData(fileType: number) {
    const { childId }: any = storage.GET_RegistrationInfo();
    return {
      t: fileType, childId: childId
    }
  }

  //开关的change事件
  switchChange(val: boolean) {
    //加载电子证照
    if (val) {
      // 证照库接口
      const { childId, enterGardenType, gradeCode }: any = storage.GET_RegistrationInfo();
      const { encryptId }: any = this.$store.getters.GET_CurrentUser;
      helperService
        .queryZZKCertData(childId, encryptId, gradeCode, enterGardenType)
        .then((res: any) => {
          if (res.data && res.data.success) {
            this.$dialog.alert({ title: "提示", message: queryZZKMessage })
              .then(() => {
                this.getAttachmentPage();
              });
          } else {
            this.$toast("电子证照加载失败！");
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
          this.$toast("电子证照加载失败！");
        });
    }
  }

  //图片change事件
  fileChange(data: IChildAttachment[], fileType: number) {
    if (data.length > 0) {
      const find = this.attachmentPage.find((u) => u.fileType == fileType);
      if (find) {
        find.childAttachmentDtos = data;
        find.childAttachmentDtos.forEach((u) => {
          u.fileTypeName = find ? find.fileTypeName : "";
        });
      }
    }
  }

  //图片删除
  fileDelete(
    data: IChildAttachment,
    nowFiles: IChildAttachment[],
    fileType: number
  ) {
    if (data.id > 0) {
      this.formModel.delIds.push(data.id);
    }

    const find = this.attachmentPage.find((u) => u.fileType == fileType);
    if (find) {
      find.childAttachmentDtos = nowFiles;
    }
  }

  nextOnClick() {
    const fileDBInfoIsUndefineds = this.attachmentPage.filter(
      (a) =>
        (a.mustSupport || (!a.mustSupport && (!a.isCheck || a.isCheck.toString() == "false"))) &&
        (a.childAttachmentDtos === null || a.childAttachmentDtos.length === 0)
    );

    if (fileDBInfoIsUndefineds.length > 0) {
      this.$toast(`请上传（${fileDBInfoIsUndefineds[0].fileTypeName}）！`);
      return;
    }

    this.attachmentPage.forEach((u) => {
      this.formModel.childAttachmentDtos.push(...u.childAttachmentDtos);
    });

    const { childId, gradeCode }: any = storage.GET_RegistrationInfo();
    this.formModel.childId = childId;
    this.formModel.gradeCode = gradeCode;

    childAttachmentService.saveAttachmentPage(this.formModel).then((res: BaseResultDto) => {
      if (res.verifyResult.success === true) {
        this.$toast({
          message: "保存成功",
          position: "top",
        });

        this.$store.dispatch("ACTION_ChildAttachments", this.formModel);
        this.$router.push(`/thisCity/regChildGarden`);
      }
      else {
        if (res.verifyResult.exceptionCode == "GOHOME") {
          this.$dialog.alert({
            message: hasRegister,
          }).then(() => {
            this.$router.push("/record");
          });
        }
        else {
          this.$toast({
            message: res.verifyResult.message,
            position: 'top'
          });
        }
      }
    });
  }

  viewLoad() {
    this.getAttachmentPage();
  }

  getAttachmentPage() {
    const { childId, enterGardenType, gradeCode }: any = storage.GET_RegistrationInfo();
    this.childId = childId;

    const input = new getAttachmentPageInput();
    input.childId = childId;
    input.enterType = enterGardenType;
    input.gradeCode = gradeCode;

    childAttachmentService
      .getAttachmentPage(input)
      .then((res: ChildAttachmentGroupDtos) => {
        this.attachmentPage = res.childAttachmentGroupDtos;
        this.attachmentPage.forEach((u: any, index: number) => {
          if (!u.hasFile) {
            u.childAttachmentDtos = [];
          }

          let isCheck = false;
          //重新报名
          if (this.attachmentPage[0].hasFile) {
            isCheck = u.mustSupport || (!u.mustSupport && u.hasFile) ? false : true;
          }
          this.$set(u, "isCheck", isCheck);
          // this.$set(u, "isCheck", false);
        });
      });
  }


  mounted() {
    this.viewLoad();
  }
}